import React, {useState} from "react";
import Headshot from "../assets/components/Headshot.js";
import Drawing from "./Drawing.js";
import DrawingMobile from "./DrawingMobile.js";
import "./Header.css";

function Header(props) {
  const [activeGroup, setActiveGroup] = useState("life")
  const [previousActiveGroup, setPreviousActiveGroup] = useState("entrepreneur")
  
  if (props.isMobile) {
    return (
      <div class="header">
      <div className="column">
        <div className="column">
          <h1 className="titleMobile">
          CIERRA BECK
          </h1>
        </div>
        <Headshot isMobile={props.isMobile} activeGroup={activeGroup} setActiveGroup={setActiveGroup} previousActiveGroup = {previousActiveGroup} setPreviousActiveGroup = {setPreviousActiveGroup}/>
        <DrawingMobile activeGroup={activeGroup} setActiveGroup={setActiveGroup} previousActiveGroup = {previousActiveGroup} setPreviousActiveGroup = {setPreviousActiveGroup}/>
      </div>
    </div>
    );
  }
  else {
    return (
      <div class="header">
      <div className="row">
        <div className="column">
          <h1 className="title">
          CIERRA BECK
          </h1>
            <Drawing activeGroup={activeGroup} setActiveGroup={setActiveGroup} previousActiveGroup = {previousActiveGroup} setPreviousActiveGroup = {setPreviousActiveGroup}/>
        </div>
        <Headshot isMobile={props.isMobile} activeGroup={activeGroup} setActiveGroup={setActiveGroup} previousActiveGroup = {previousActiveGroup} setPreviousActiveGroup = {setPreviousActiveGroup}/>
      </div>
    </div>
    );
  }
}
export default Header;
