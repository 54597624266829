import React, { useState } from "react";
import './ProjectSlider.css'
import LearnMore from "./LearnMore.js"

const ProjectSlider = (props) => {

    const [activeProject, setActiveProject] = useState(0)
  return (
    <div className={props.isMobile? "projectSliderMobile" :"projectSlider"}>
      <div className={props.isMobile ? "projectListMobile" : "projectList"}>
      {props.projectList.map((item, index) => (
        <div className={index === activeProject ? (props.isMobile ? "activeProjectMobile" : "activeProject" ) : (props.isMobile ? "projectMobile": "project")} onClick={() => setActiveProject(index)}>
            <div className={index === activeProject ? "activeTitleDiv" : ""}>
          <p className={index === activeProject ? (props.isMobile ? "activeProjectTitleMobile": "activeProjectTitle" ) : (props.isMobile ? "projectTitleMobile" : "projectTitle")}>{item.title}</p>
          </div>
          {index === activeProject && 
          <div className="activeProjectContent">
            <div className={props.isMobile ? "coreTagsMobile" : "coreTags"}> 
            {item.engTag && 
                <div className="engTag"></div>
            }
            {item.entrepTag && 
                <div className="entrepTag"></div>
            }
            {item.designTag && 
                <div className="designTag"></div>
            }
              </div>
              <p className={props.isMobile ? "activeDescriptionMobile" : "activeDescription"}>{item.description}</p>
              <div className="upperLine"></div>
              <div className="activeBottomDiv">
              <p className={props.isMobile ? "moreDetailsMobile" : "moreDetails"}>{item.moreDetails}</p>
              <LearnMore isMobile = {props.isMobile} href={item.href}/>
              </div>
              {/* TODO: tags coded but i dont like how they look for now <div className="iconTagList">
                {item.iconTags.map((item, index) => (
                    <img className = "iconTag" src={item.src} alt={item.alt}/>
                ))}
              </div> */}
            </div>
            }
        </div>
      ))}
      </div>
      <div className={props.isMobile ? "dottedTrackMobile" : "dottedTrack"}></div>
    </div>)
}

export default ProjectSlider;