import crunchbaseIcon from "../images/crunchbaseIcon.png";
import emailIcon from "../images/emailIcon.png";
import githubIcon from "../images/githubIcon.png";
import instagramIcon from "../images/instagramIcon.png";
import linkedinIcon from "../images/linkedinIcon.png";
import mediumIcon from "../images/mediumIcon.png";

const footerLinks = [
  {
    name: "medium",
    href: "https://medium.com/@cierrabeck422",
    src: mediumIcon,
    className: "footerItemDark desktopFooter",
    classNameMobile: "footerItemDark mobileFooter",
  },
  {
    name: "github",
    href: "https://github.com/cierrabeck",
    src: githubIcon,
    className: "footerItemMedium desktopFooter",
    classNameMobile: "footerItemMedium mobileFooter",
  },
  {
    name: "linkedin",
    href: "https://www.linkedin.com/in/cierrabeck/",
    src: linkedinIcon,
    className: "footerItemLight desktopFooter",
    classNameMobile: "footerItemLight mobileFooter",
  },
  { name: "email", 
    href: "mailto: cierrabeck422@gmail.com", 
    src: emailIcon, 
    className: "footerItemDark desktopFooter",
    classNameMobile: "footerItemDark mobileFooter",
  },
  {
    name: "instagram",
    href: "https://www.instagram.com/cierra_beck/",
    src: instagramIcon,
    className: "footerItemMedium desktopFooter",
    classNameMobile: "footerItemMedium mobileFooter",
  },
  {
    name: "dribbble",
    href: "https://dribbble.com/cierrabeck",
    src: crunchbaseIcon,
    className: "footerItemLight desktopFooter",
    classNameMobile: "footerItemLight mobileFooter",
  },
];

export default footerLinks;
