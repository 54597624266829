import "./Footer.css";
import React from "react";
import footerLinks from "../assets/constants/footerLinks.js"


function Footer(props) {

  return (
    <div className="footer">
      <div className="footerGroup">
      {footerLinks.map((link, index) => (
        <div className={props.isMobile ? link.classNameMobile : link.className}>
          <a href={link.href} target="_blank" rel="noreferrer">
          <img className={props.isMobile ? "footerImgMobile" : "footerImg"} src={link.src} alt={link.name + "will open in a new tab on click"}></img>
          </a>
        </div>
      ))}
      </div>
      <div className={props.isMobile ? "footerLineMobile" : "footerLine"}></div>
    </div>
  );
}

export default Footer;
