import './SplashScreen.css'
import React from "react"

function SplashScreen(props) {

    return (
        <div className="loadingBlock">
            <p className="fadingTextFirst"> * hello * </p>
            <p className="fadingTextSecond"> and welcome to my website </p>
        </div>
    )
}

export default SplashScreen;