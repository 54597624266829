const groupList = [
    {
        "name": "engineering",
        "activeClass": "circleButtonActive circleButton__leftActive",
        "standardClass": "circleButton circleButton__left",
        "activeInnerClass": "innerCircleButtonActive innerCircleButton__left",
        "standardInnerClass": "innerCircleButton innerCircleButton__left",
        
        "activeClassMobile": "circleButtonActiveMobile circleButton__leftActiveMobile",
        "standardClassMobile": "circleButtonMobile circleButton__leftMobile",
        "activeInnerClassMobile": "innerCircleButtonActiveMobile innerCircleButton__leftMobile",
        "standardInnerClassMobile": "innerCircleButtonMobile innerCircleButton__leftMobile",
    }, 
    {
        "name": "life",
        "activeClass": "circleButtonActive circleButton__rightActive",
        "standardClass": "circleButton circleButton__right",
        "activeInnerClass": "innerCircleButtonActive innerCircleButton__right",
        "standardInnerClass": "innerCircleButton innerCircleButton__right",

        "activeClassMobile": "circleButtonActiveMobile circleButton__rightActiveMobile",
        "standardClassMobile": "circleButtonMobile circleButton__rightMobile",
        "activeInnerClassMobile": "innerCircleButtonActiveMobile innerCircleButton__rightMobile",
        "standardInnerClassMobile": "innerCircleButtonMobile innerCircleButton__rightMobile",
    }, 
    {
        "name": "designer",
        "activeClass": "circleButtonActive circleButton__topActive",
        "standardClass": "circleButton circleButton__top",
        "activeInnerClass": "innerCircleButtonActive innerCircleButton__top",
        "standardInnerClass": "innerCircleButton innerCircleButton__top",

        "activeClassMobile": "circleButtonActiveMobile circleButton__topActiveMobile",
        "standardClassMobile": "circleButtonMobile circleButton__topMobile",
        "activeInnerClassMobile": "innerCircleButtonActiveMobile innerCircleButton__topMobile",
        "standardInnerClassMobile": "innerCircleButtonMobile innerCircleButton__topMobile",
    }, 
    {
        "name": "entrepreneur",
        "activeClass": "circleButtonActive circleButton__bottomActive",
        "standardClass": "circleButton circleButton__bottom",
        "activeInnerClass": "innerCircleButtonActive innerCircleButton__bottom",
        "standardInnerClass": "innerCircleButton innerCircleButton__bottom",

        "activeClassMobile": "circleButtonActiveMobile circleButton__bottomActiveMobile",
        "standardClassMobile": "circleButtonMobile circleButton__bottomMobile",
        "activeInnerClassMobile": "innerCircleButtonActiveMobile innerCircleButton__bottomMobile",
        "standardInnerClassMobile": "innerCircleButtonMobile innerCircleButton__bottomMobile",
    }, 

]

export default groupList;