import "./Drawing.css";
import React from "react";
import binaryCircle from "../assets/images/binaryCircle.png"
import edgeCovers from "../assets/images/edgeCovers.png"
import quadDesign from "../assets/images/quadDesign.png"
import quadDesignLife from "../assets/images/quadDesignLife.png"
import quadDesignEng from "../assets/images/quadDesignEng.png"
import quadDesignEntrep from "../assets/images/quadDesignEntrep.png"
import quadDesignDesign from "../assets/images/quadDesignDesign.png"
import groupList from "../assets/constants/groupList.js"
import InnerText from "../assets/components/InnerText.js"


function Drawing(props) {

    function handleGroupChange(newActiveGroup) {
        props.setPreviousActiveGroup(props.activeGroup)
        props.setActiveGroup(newActiveGroup)
    }

    const edgeCoversClassName= ( props.activeGroup === "life" ? props.previousActiveGroup === "engineering" ? "edgeEngToLife" : props.previousActiveGroup === "designer" ? "edgeDesToLife" : "edgeEntrepToLife" :
                                props.activeGroup === "engineering" ? props.previousActiveGroup === "life" ? "edgeLifeToEng" : props.previousActiveGroup === "designer" ? "edgeDesToEng" : "edgeEntrepToEng" :
                                props.activeGroup === "designer" ? props.previousActiveGroup === "engineering" ? "edgeEngToDes" : props.previousActiveGroup === "life" ? "edgeLifeToDes" : "edgeEntrepToDes" :
                                props.activeGroup === "entrepreneur" ? props.previousActiveGroup === "engineering" ? "edgeEngToEntrep" : props.previousActiveGroup === "designer" ? "edgeDesToEntrep" : "edgeLifeToEntrep" : ""
                                )
   
    const quadDesignColor = props.activeGroup === "life" ? quadDesignLife : props.activeGroup === "engineering" ? quadDesignEng : props.activeGroup === "designer" ? quadDesignDesign : quadDesignEntrep;
  return (
    <div className="drawing">
        <img className={"quadDesign " + edgeCoversClassName} alt="all white design with two large rectangles and a triangle" src={quadDesign}/>
        <img className={"quadDesignColor " + edgeCoversClassName} alt="all white design with two large rectangles and a triangle" src={quadDesignColor}/>
        <img className="binaryImage" alt="binary code spinning around in a circle" src={binaryCircle}></img> 
        <img className={"edgeCovers " + edgeCoversClassName} alt="white lines with a black outline covering segments of the binary code" src={edgeCovers}></img> 
            <InnerText activeGroup={props.activeGroup}></InnerText>
            {groupList.map((item, index) => (
            <div onClick={() => handleGroupChange(item.name)} className={props.activeGroup === item.name ? item.activeClass : item.standardClass}>
                <div className={props.activeGroup === item.name ? item.activeInnerClass :item.standardInnerClass}></div>
            </div>
        ))}
    </div>
  );
}

export default Drawing;
