import React from "react";
import innerTextLife from "../images/innerTextLife.png"
import innerTextDesign from "../images/innerTextDesign.png"
import innerTextEng from "../images/innerTextEng.png"
import innerTextEntrep from "../images/innerTextEntrep.png"
import "./InnerTextMobile.css"

function InnerTextMobile(props) {

    const groupMap = [
        {
            "name": "engineering",
            "src": innerTextEng,
            "alt":"engineering provides a technical foundation, with a focus on problem solving and optimization",
            "className": "innerTextMobile",
        }, 
        {
            "name": "life",
            "src": innerTextLife,
            "alt":"life built from balancing creativity and functionality across three primary disciplines",
            "className": "innerTextMobile",
        }, 
        {
            "name": "designer",
            "src": innerTextDesign,
            "alt":"design adds a layer of user-centric thinking, focusing on functionality and aesthetics",
            "className": "innerTextMobile",
        }, 
        {
            "name": "entrepreneur",
            "src": innerTextEntrep,
            "alt":"entrepreneurship bridges the gap between idea and execution, strategizing to bring innovations to life",
            "className": "innerTextMobile",
        }, 
    
    ]
    const activeGroupMap = groupMap.find((group) => group.name === props.activeGroup);
    return (
        <div>
            <img className={activeGroupMap.className} src={activeGroupMap.src} alt={activeGroupMap.alt}></img>
        </div>
    )
}
export default InnerTextMobile