import React, { useState } from "react";
import './Projects.css'
import ProjectSlider from '../assets/components/ProjectSlider.js'
import projectList from '../assets/projectList.js'
import highlightsByte from '../assets/images/highlights/highlightsByte.png'
import highlightsEleve from '../assets/images/highlights/highlightsEleve.png'
import highlightsLeft from '../assets/images/highlights/highlightsLeft.png'
import highlightsRight from '../assets/images/highlights/highlightsRight.png'


const Projects = (props) => {
  const tagButtons = [
    { id: 1, label: "machine learning" },
    { id: 2, label: "app dev" },
    { id: 3, label: "web dev" },
    { id: 4, label: "health" },
    { id: 5, label: "graphics" },
    { id: 6, label: "networks" },
    { id: 7, label: "mentorship" },
    { id: 8, label: "productivity" },
    { id: 9, label: "culinary" },
  ];

  const [activeButton, setActiveButton] = useState(0);
  const handleButtonClick = (id) => {
    setActiveButton(id);
  };
  return (
    <div>
      <div className={props.isMobile ? "myWorkMobileDiv" : "myWorkDiv"}>
        <h1 className={props.isMobile ? "myWorkMobile" : "myWork"}> MY WORK </h1>
        <img className={props.isMobile ? "highlightsImageMobile firstImageMobile" : "highlightsImage firstImage"} src={highlightsLeft} alt="highlights with a colon and the start of square brakcet"/>
        <a className="highlightLink" href="https://www.byte-smarterkitchen.com/">
        <img className={props.isMobile ? "highlightsImageMobile" : "highlightsImageTaller"} src={highlightsByte} alt="dark grey beveled text reading byte, clicking will take you to the byte website "/>
        </a>
        <a className="highlightLink" href="https://www.byte-smarterkitchen.com/eleve">
        <img className={props.isMobile ? "highlightsImageMobile" : "highlightsImageTaller"} src={highlightsEleve} alt="white text with elegent letters reading eleve, clicking will take you to the eleve home page"/>
        </a>
        <img className={props.isMobile ? "highlightsImageMobile" : "highlightsImage"} src={highlightsRight} alt="the end of a square brakcet"/>
      </div>
      <div class={props.isMobile ? "tagsMobile" : "tags"}>
        <div className="tagButton">
              <button
                key={0}
                onClick={() => handleButtonClick(0)}
                className={activeButton === 0 ? (props.isMobile ? "tagTextActiveMobile" : "tagTextActive") : (props.isMobile ? "tagTextMobile" : "tagText")}
              >
                all projects
              </button>
            </div>
          {/* TODO: add tag style for mobile(currently only desktop optimized) */}
          {tagButtons.map(({ id, label }) => (
            <div className={id>4 ? (props.isMobile ? "tagButtonBottomRowMobile" : "tagButtonBottomRow") : (props.isMobile ? "tagButtonMobile" : "tagButton")}>
              <button
                key={id}
                onClick={() => handleButtonClick(id)}
                className={activeButton === id ? (props.isMobile ? "tagTextActiveMobile" : "tagTextActive") : (props.isMobile ? "tagTextMobile" : "tagText")}
              >
                {label}
              </button>
            </div>
          ))}
        </div>
      {activeButton === 0 && 
      <ProjectSlider isMobile={props.isMobile} projectList = {projectList}/>
      }
      {activeButton !== 0 && 
      <ProjectSlider isMobile={props.isMobile} projectList = {projectList.filter((project) => (project.tags.includes(activeButton)))}/>
      }
    </div>
    )
}

export default Projects;