import headshot from "../images/headshot.png"
import "./Headshot.css";
function Headshot(props) {

    function handleGroupChange(newActiveGroup) {
        props.setPreviousActiveGroup(props.activeGroup)
        props.setActiveGroup(newActiveGroup)
    }

return (
    <div className={props.isMobile ? "headshotDivMobile" : "headshotDiv"}>
        <p onClick={() => handleGroupChange("engineering")} className={(props.isMobile? "headshotTitleMobile": "headshotTitle") +  " "+ (props.activeGroup === "engineering" ? (props.isMobile ? "activeEngineerTitleMobile" : "activeEngineerTitle") : (props.isMobile ? "engineerTitleMobile": "engineerTitle"))}> ENGINEER </p>
        <p onClick={() => handleGroupChange("designer")} className={(props.isMobile? "headshotTitleMobile": "headshotTitle") + " "+(props.activeGroup === "designer" ? (props.isMobile ? "activeDesignerTitleMobile" : "activeDesignerTitle") : (props.isMobile ? "designerTitleMobile": "designerTitle"))}> DESIGNER </p>
        <p onClick={() => handleGroupChange("entrepreneur")} className={(props.isMobile? "headshotTitleMobile": "headshotTitle") +  " "+ (props.activeGroup === "entrepreneur" ? (props.isMobile ? "activeEntrepreneurTitleMobile" : "activeEntrepreneurTitle") : (props.isMobile ? "entrepreneurTitleMobile": "entrepreneurTitle"))}> ENTREPRENEUR </p>
        <img
            className={props.isMobile ? "headshotMobile" : "headshot"}
            src={headshot}
            alt="headshot of cierra with her role titles - entrepreneur, designer, engineer"
        ></img>
    </div>
); }

export default Headshot;

