import React from "react";
import './LearnMore.css'
import arrow from '../images/arrow.png'

const LearnMore = (props) => {

  return (
      <div className={props.isMobile ? "learnMoreMobile" : "learnMore"}>
        <a className="learnMoreTag" href={props.href}>
        <p className={props.isMobile ? "learnMoreTextMobile" : "learnMoreText"}> learn more</p>
        <img className={props.isMobile ? "arrowMobile" : "arrow"} src={arrow} alt="pink arrow with a light pink stripe"/>
        </a>
      </div>

    )
}

export default LearnMore;